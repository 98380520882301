import * as React from 'react'
import cn from 'classnames'
import { Tooltip } from '../../atoms/Tooltip'
import { Avatar } from '../../atoms/Avatar'

export type AvatarButtonProps = {
  /**
   * If passed, the button will be rendered as a link.
   */
  href?: string
  /**
   * Src of the avatar image.
   */
  src?: string | null
  /**
   * Generic onClick mouse handler which could be for an anchor or button element
   */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  /**
   * Size of the avatar - xs ("20px"), sm ("32px"), md ("48px"), lg ("80px"), xl ("128px")
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  /**
   * Used if the avatar can't be loaded - this should be something small that can fit within the constraints of the avatar size. Initials usually work best for this.
   * @example "JD"
   */
  fallbackText: string
  /**
   * Content to display in the tooltip. A tooltip won't be rendered
   * @example "John Doe"
   */
  tooltipContent?: string
  /**
   * Alt text to pass through to the avatar.
   */
  alt?: string
  /**
   * Whether or not to show inactive styling for the avatar
   */
  isInactive?: boolean
  className?: string
}

export const AvatarButton: React.VFC<AvatarButtonProps> = (props) => {
  const {
    size = 'lg',
    onClick,
    href,
    fallbackText,
    src,
    tooltipContent,
    className,
    isInactive,
    alt,
  } = props

  const nonPropagatingOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClick?.(e)
  }

  const sharedWrapperProps = {
    className: cn('rounded-full appearance-none', className),
    onClick: nonPropagatingOnClick,
  }

  const avatarProps = {
    fallbackText,
    size,
    src,
    alt,
    isInactive,
  }

  if (href)
    return (
      <Tooltip content={tooltipContent} disabled={!tooltipContent}>
        <a href={href} {...sharedWrapperProps}>
          <Avatar {...avatarProps} />
        </a>
      </Tooltip>
    )

  return (
    <Tooltip content={tooltipContent} disabled={!tooltipContent}>
      <button type="button" {...sharedWrapperProps}>
        <Avatar {...avatarProps} />
      </button>
    </Tooltip>
  )
}
